/* page size settings */
@page {
  size: auto; /* auto is the initial value */
  margin: 0mm; /* this affects the margin in the printer settings */
}

body {
  flex-direction: column;
  align-items: center;
  font-family: Roboto;
  line-height: initial;
}

label {
  margin-bottom: 0;
}
.dropdown-item.active {
  color: white;
}

.ageDisplay h1 {
  font-size: 47px;
  margin: 0;
}

.questionsPage {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.question-header {
  /* border-bottom: 1px solid lightgray; */
  padding: 7px 10px 0px 10px;
}

input[type='number'].age {
  width: 4ch;
  margin: 0 29px 0 10px;
}

input.age {
  border: none;
  border-bottom: 2px solid gray;
}

.welcomeScreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(237, 236, 233);
  display: flex;
  align-items: center;
  justify-content: center;
}

html,
body {
  width: 100%;
  min-height: 100%;
}

.loginBox {
  flex-direction: column;
  display: flex;
  padding: 26px 24px 31px;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10% 0;
}

.login img {
}

.loginBox input {
  font-size: 24px;
  margin: 2px 0 16px 0;
  background: #fbfbfb;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.07);
  border: 1px solid #ddd;
  padding: 3px;
}

.loginBox label {
  color: #777;
  font-size: 15px;
}

.loginBox button {
  background: #1485cc;
  border: none;
  border-radius: 5px;
  height: 30px;
  width: 83px;
  color: white;
  font-weight: 500;
  outline: none;
}

.header .logout {
  font-size: 16px;
  font-style: italic;
  margin-left: 1ch;
}

.questions-page {
  display: flex;
  flex-wrap: wrap;
}

.actionBar {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 3px 7px;
  font-size: 12px;
  box-sizing: border-box;
}

.header .right a {
  font-size: 16px;
}

.button {
  padding: 7px 14px;
  background: hsl(353, 60%, 47%);
  border-color: hsl(353, 60%, 47%) hsl(353, 60%, 47%) hsl(353, 60%, 24%);
  box-shadow: 0 2px 11px hsla(0, 0%, 0%, 0.2);
  color: #fff;
  font-size: 19px;
  border-width: 1px;
  cursor: pointer;
  border-style: solid;
  border-radius: 3px;
}

.button:active {
  background: hsla(353, 60%, 38%, 1);
  border-color: hsla(353, 60%, 38%, 1);
  box-shadow: none;
}

.header .reginfo .upper {
  font-weight: 700;
  font-family: roboto slab;
  color: hsla(0, 0%, 71%, 1);
}

.header .reginfo {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  line-height: 1;
}

.ReportView .reportHeader {
  width: 100%;
  display: block;
  margin-top: 0;
}

.bottomRow .button {
  margin-left: 14px;
}

.bottomRow .ageDisplay {
  font-size: 30px;
  color: black;
  opacity: 0.9;
  background: white;
  padding: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
}

.checkboxHighlight {
  position: absolute;
  background: hsla(0, 100%, 50%, 0.63);
  width: 11px;
  height: 10px;
}

.textFill {
  position: absolute;
}

.ReportView .appleImg {
  height: 154px;
  float: right;
  margin-top: 8px;
  margin-left: 10px;
  /* display: none; */
}

.customizedForRow {
  margin: 8px 0;
}

.metaFields {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 5px 0;
  flex-wrap: wrap;
}

.field {
  margin: 12px 0;
  font-size: 18px;
}

.metaFields .field .fieldValue {
  background: hsla(0, 0%, 92%, 1);
  margin: 0 5px;
  padding: 2px;
  width: 25ch;
  display: inline-block;
  height: 21px;
  padding-left: 4px;
  box-sizing: content-box;
}

.metaFields .fieldLabel {
  font-weight: bold;
}

span.field.fullWidth {
  width: 100%;
  display: flex;
  align-items: center;
}

.ageValue {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33.333333%;
}

.ageValue__value {
  font-size: 38px;
  padding: 0 6px;
}

.ageValue__descc {
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  align-items: center;
}

.ageValue__desc {
  font-weight: bold;
  font-size: 17px;
  text-align: center;
}

.ageValue__subdesc {
  font-size: 14.5px;
  text-align: center;
}

.ageValues {
  width: 100%;
  display: flex;
}

.rcmd {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.printInstructions {
  font-size: 26px;
  color: white;
  opacity: 0.9;
}

.applec {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  border-left: none;
}

.applec img {
  height: 43px;
}

.loginHeader {
  width: 100%;
  display: flex;
  padding: 10px;
  background-color: #be2c40;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
  color: white;
  font-size: 21px;
  font-weight: 500;
}

.loginInner {
}

.loginContainer {
  margin-top: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
  background: white;
  border-radius: 5px;
  overflow: hidden;
}

.welcomeScreen .loginRow {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.loginBox button:active {
  background: hsla(203, 82%, 35%, 1);
}

@keyframes headerEntry {
  0% {
    top: -52;
  }
  100% {
    top: 0;
  }
}

.genderButton {
  font-size: 30px;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.61);
  cursor: pointer;
}

div.genderButtons {
  display: flex; /* width: 29%; */
  justify-content: space-around;
}

.genderButton.genderButton-active {
  font-weight: 400;
  color: white;
}

/*.intrch__checkbox {
    height: 15px;
    width: 15px;
    border: 2px solid black;
    margin-right: 4px;
    font-size: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}*/

.loginBox select {
  margin-bottom: 17px;
}

.sheader a {
  font-size: 17px;
  margin-left: 4px;
  color: #2685ca;
  text-decoration: none;
}

.accountFooter {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 5px;
  background: hsla(0, 0%, 30%, 1);
  color: white;
  width: 100%;
  box-sizing: border-box;
}

.accountFooter span.upper {
  font-family: roboto;
  font-weight: 500;
  /* color: hsla(0,0%,87%,1); */
  font-size: 18px;
}

.accountFooter span.lower {
  font-size: 15px;
  font-weight: 100;
  display: flex;
  justify-content: space-between;
}

.questionsView {
  flex-grow: 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.accountFooter a {
  color: #2685ca;
  font-weight: bold;
  text-decoration: none;
}

.sidebar .entry {
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  height: 32px;
  box-sizing: border-box;
  align-items: center;
  padding: 0 0 0 5px;
  font-weight: 100;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  flex-shrink: 0;
}

.header .right {
  height: 100%;
  display: flex;
  align-items: center;
}

.entry.selected {
  background-color: #f2f2f2;
  /* border-left-color: #2685ca; */
}

.sidebar .entries {
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cmenu {
  position: absolute;
  background: white;
  color: #333;
  padding: 6px 0px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: none;
}

.cmenu.sbtmore {
  top: 45px;
  left: 3px;
  right: 3px;
}

.cmenu__item {
  padding: 5px 12px;
  cursor: pointer;
}

.cmenu__item:hover {
  background: #eee;
}

.cmenu .cmenu-visible {
  display: block;
}

.cmenu.cmenu-visible {
  display: block;
}

.loading img {
  margin: auto;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
}

html,
body {
  height: 100%;
}

.genderButton:nth-of-type(2) {
  margin-left: 7px;
}
.dividerBar.thick {
  height: 8px;
}

.sheader__button.left {
}

.sheader__buttons.right .sheader__button {
}

.entries .listSeparator {
  height: 1px;
  width: 100%;
  background: #f2f2f2;
  flex-shrink: 0;
}

.outerContainer.loggedIn .appContainer {
  display: flex;
}

.outerContainer.loggedIn {
}

.loggedIn .welcomeScreen {
  display: none;
}

.outerContainer {
  height: 100%;
}

.welcome__imgc {
  position: fixed;
  bottom: 13px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.welcome {
  display: flex;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.129412) 0px 1px 3px;
  padding: 30px;
  background: white;
  flex-direction: column;
  font-size: 24px;
  max-width: 800px;
}

.welcome select {
  font-size: 24px;
  margin: 16px 0;
}

.welcome__step {
  display: flex;
  flex-direction: column;
}

.welstep h2 {
  margin-top: 0;
  text-align: center;
}

.welstep__foot {
  display: flex;
  justify-content: center;
}

.welstep p {
  text-align: center;
}

.welcome__step button {
  font-size: 21px;
  align-self: center;
}

.not_en .ReportView .field.fullWidth {
  margin-top: 3px;
}
.not_en .ageValue__desc {
  width: 120%;
}
.not_en .rcmd__title {
  margin: 7px 0 5px 0;
}
.not_en .rcmd__title {
  margin: 7px 0 5px 0;
}
.not_en .ReportView .appleImg,
.locale__es .ReportView .appleImg,
.locale__pt .ReportView .appleImg {
  display: none;
}
.not_en .dividerBar.thick {
  height: 6px;
}
